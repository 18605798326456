import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { sessionLoggedIn } = useAuth();

  const renderRoute = (props) => {
    if (sessionLoggedIn()) {
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{ pathname: "/login", state: { referer: props.location } }}
        />
      );
    }
  };

  return <Route {...rest} render={renderRoute} />;
};

export default PrivateRoute;
