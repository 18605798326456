import ApiClient from "./ApiClient";
import { URIS, BACKEND_STATUS } from "./ApiClient";
import resolve from "./Resolve";

export const GetExpenseBillsQty = async (userId) => {
  // console.log("GETting from " + URIS.EXPENSE_BILLS_QTY);

  // const result = await resolve(
  //   ApiClient.get(URIS.EXPENSE_BILLS_QTY, { params: { userId: userId } })
  // );

  // if (result?.status === BACKEND_STATUS.SUCCESS) {
  //   if (result?.data) {
  //     return result.data;
  //   }
  // }

  // NOTE: Only for dev.
  const debug_result = Math.floor(Math.random() * 10);
  await new Promise((r) => setTimeout(r, 2000));
  return debug_result;
};

export const GetExpenseBills = async (userId) => {
  console.log("GETting from " + URIS.EXPENSE_BILLS);

  const result = await resolve(
    ApiClient.get(URIS.EXPENSE_BILLS, { params: { userId: userId } })
  );

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};

export const GetExpenseBill = async (expenseId) => {
  console.log("GETting from " + URIS.EXPENSE_BILL);

  const result = await resolve(
    ApiClient.get(URIS.EXPENSE_BILL, { params: { expense_id: expenseId } })
  );

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};

export const AcceptExpenseBill = async (expenseBill) => {
  const debugUrl = "http://localhost:3333/";
  const debugPayload = { id: 1, name: "debug expensebill" };

  const result = await resolve(ApiClient.post(debugUrl, debugPayload));

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};

export const AcceptExpenseBills = async (expenseBills) => {
  // Send to common service on dev_server
  const debugUrl = "/api/expense/acceptance";
  const result = await resolve(ApiClient.post(debugUrl, expenseBills));

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};

export const RejectExpenseBill = async (expenseBill) => {
  const debugUrl = "http://localhost:3333/";
  const debugPayload = { id: 1, name: "debug expensebill" };

  const result = await resolve(ApiClient.post(debugUrl, debugPayload));

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};
