import ApiClient from "./ApiClient";
import { URL_PREFIX, URIS, BACKEND_STATUS } from "./ApiClient";
import resolve from "./Resolve";

export const PostLogin = async (userName, password, callBack) => {
  // Only for DEV
  if (userName === "debug") {
    const debug_result = { data: { token: "12345devtoken", userId: "debug", name: "janne" } };

    if (callBack) {
      let expirationTime10Hours = new Date();
      expirationTime10Hours.setHours(expirationTime10Hours.getHours() + 10);
      callBack(userName, debug_result.data.userId, debug_result.data.token, expirationTime10Hours);
    }
    return debug_result.data;
  }
  // -------------------------------
  const result = await resolve(ApiClient.post(URL_PREFIX + "/" + URIS.LOGIN, { user_name: userName, password: password }));

  console.log("Access token = " + result?.data?.access_token);

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data?.access_token != "") {
      let userId;
      let fullName;
      if (result?.data?.userId) {
        userId = result?.data?.userId;
      }

      if (result?.data?.name) {
        fullName = result?.data?.name;
      }

      console.log("full name = " + fullName);

      if (callBack) {
        let expirationTime10Hours = new Date();
        expirationTime10Hours.setHours(expirationTime10Hours.getHours() + 10);
        callBack(userName, fullName, userId, result.data.access_token, expirationTime10Hours);
      }
    }
  }

  if (result?.data) {
    return result.data;
  }
};
