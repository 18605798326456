import React, { useState } from "react";
import { Container } from "react-bootstrap";
import TravelBillField from "../TravelBill/TravelBillField";
import "../TravelBill/TravelBill.css";
import { useTranslation } from "react-i18next";
import { FaImage } from "react-icons/fa";
import { GetAttachmentData } from "../../Services/Api/Attachments";
import { toast } from "react-toastify";
import ViewAttachment from "../ViewAttachment";

const UserDetails = ({ user }) => {
  const { t } = useTranslation();
  const [showAttachment, setShowAttachment] = useState(false);
  const [attachmentData, setAttachmentData] = useState("");

  // if (!user) return "ei useria";

  console.log("UserDetails, user = " + JSON.stringify(user));

  const notify = (type, message) => {
    if (type === "error") {
      toast.error(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      toast.success(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  // const onAttachmentClicked = async (attachment) => {
  //   const attachmentData = await GetAttachmentData(attachment);

  //   if (attachmentData?.data) {
  //     setShowAttachment(true);
  //     setAttachmentData(attachmentData.data);
  //   } else {
  //     notify("error", "TRANSLATE: Cannot open attachment");
  //   }
  // };

  // const renderAttachmentLinks = () => {
  //   let result = "";
  //   if (!expenseBill?.attachments) return result;

  //   result = expenseBill.attachments.map((item) => {
  //     return (
  //       <span
  //         key={item.id}
  //         onClick={() => {
  //           onAttachmentClicked(item);
  //         }}
  //       >
  //         <FaImage style={{ margin: 5, color: "green" }} size={40} />
  //       </span>
  //     );
  //   });

  //   return result;
  // };

  return (
    <Container className="page-container">
      {/* <div style={{ textAlign: "left" }}>
        <h3 className="container-header">{expenseBill.expenseName}</h3>
      </div> */}
      <div style={{ textAlign: "left" }}>
        <TravelBillField
          caption={t("name")}
          value={user?.name}
        />
        <TravelBillField
          caption={t("passWord")}
          value={user?.passWord}
        />
        <TravelBillField
          caption={t("deviceName")}
          value={user?.deviceName}
        />
        <TravelBillField
          caption={t("deviceNameLastStatus")}
          value={user?.deviceNameLastStatus}
        />
        <TravelBillField
          caption={t("apikey")}
          value={user?.apikey}
        />
        {/* <TravelBillField caption={t("attachments")} value={""} /> */}

        {/* {renderAttachmentLinks()} */}
      </div>
      {/* <ViewAttachment
        attachmentDescription={expenseBill.expenseDescription}
        modalVisible={showAttachment}
        attachmentData={attachmentData}
        modalClosed={() => {
          setShowAttachment(false);
        }}
      /> */}
    </Container>
  );
};

export default UserDetails;
