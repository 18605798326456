import React, { useState, useEffect, useCallback } from "react";
import { AuthContext } from "./Context/AuthContext";
import AppRouter from "./Navigation/AppRouter";
import Footer from "./Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import TopNavigation from "./Navigation/TopNavigation";
import { STORAGE } from "./Constants";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

let logoutTimer;

const App = () => {
  const [token, setToken] = useState(false);
  const [userName, setUserName] = useState(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((userName, name, userId, token, expirationTime) => {
    
    console.log("login(callback) userName = " + userName + ", name = " + name);

    setUserName(name);
    setToken(token);
    const expiration =
      expirationTime || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(expiration);

    sessionStorage.setItem(
      STORAGE.KEY_USER_DATA,
      JSON.stringify({
        token,
        expirationTime: expiration.toISOString(),
        userName: userName,
        userId: userId,
        name: name
      })
    );
  }, []);

  const logout = useCallback(() => {
    sessionStorage.removeItem(STORAGE.KEY_USER_DATA);
    setUserName(null);
    setToken(null);
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(
      sessionStorage.getItem(STORAGE.KEY_USER_DATA)
    );
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expirationTime) > new Date()
    ) {
      if (storedData.userName && storedData.userId) {
        login(
          storedData.userName,
          storedData.userId,
          storedData.token,
          new Date(storedData.expirationTime)
        );
      }
    } else {
      sessionStorage.removeItem(STORAGE.KEY_USER_DATA);
    }
  }, [login]);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userName: userName,
          login: login,
          logout: logout,
          sessionLoggedIn: () => {
            const storedData = JSON.parse(
              sessionStorage.getItem(STORAGE.KEY_USER_DATA)
            );
            if (storedData?.token) {
              return true;
            } else {
              return false;
            }
          },
        }}
      >
        <BrowserRouter>
          <Container
            fluid
            style={{
              marginTop: !!token ? "60px" : "0px",
              paddingLeft: "0px",
              marginLeft: "0px",
            }}
          >
            {!!token && (
              <Row>
                <Col>
                  <TopNavigation />
                </Col>
              </Row>
            )}
            <Row>
              <Col
                style={{
                  paddingLeft: "0px",
                  marginLeft: "0px",
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              >
                <Container fluid>
                  <AppRouter />
                </Container>
              </Col>
            </Row>
            <Footer />
          </Container>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
