import axios from "axios";
import { BASE_URL } from "../../Config";
import { getToken } from "../../Utils/Storage";

export const URL_PREFIX = "muuntuva-api";

export const URIS = {
  VERSION: "app/version",
  LOGIN: "auth/login",
  REFRESH: "refresh",
  LOGOUT: "logout",
  HANDBOOKS: "travel/handbooks",
  TRAVEL_BILLS_QTY: "travel/travelbillsqty",
  EXPENSE_BILLS_QTY: "travel/expensebillsqty",
  TRAVEL_BILLS: "travel/travelbills",
  TRAVEL_BILL: "travel/travelbill",
  EXPENSE_BILLS: "travel/expensebills",
  EXPENSE_BILL: "travel/expensebill",
  ACCEPT_TRAVEL_BILL: "travel/accepttravelbill",
  ACCEPT_EXPENSE_BILL: "expense/acceptexpensebill",
  REJECT_TRAVEL_BILL: "travel/rejecttravelbill",
  REJECT_EXPENSE_BILL: "expense/rejectexpensebill",
  ATTACHMENT_DATA: "attachment/image",
};

export const BACKEND_STATUS = {
  SUCCESS: 200,
  FAILED: "failed",
};

// TODO: Add token to header
const defaultOptions = {
  baseURL: BASE_URL,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-session-token": "",
  },
};

let ApiClient = axios.create(defaultOptions);

ApiClient.interceptors.request.use(function (config) {
  const token = getToken();

  // config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers["x-session-token"] = token;

  return config;
});

// TODO: This could be done such as: function getApiClientInstance .. which would contain some initializations (token etc)

export default ApiClient;
