import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form } from "react-bootstrap";
import { GetTravelBills, AcceptTravelBills } from "../../Services/Api/TravelBills";
import { getUserId } from "../../Utils/Storage";
import { useTranslation } from "react-i18next";
import { FaCheck, FaGlasses } from "react-icons/fa";
import { toast } from "react-toastify";

const Settings = (props) => {
  const [travelBills, setTravelBills] = useState([]);
  const { t } = useTranslation();
  const [acceptVisible, setAcceptVisible] = useState(false);

  // TODO: Remove later
  const [debugInfo, setDebugInfo] = useState("");

  const settings = [{id: 1, "name": "Email", "value": "monkki@mahonen.fi"}, {id: 2, "name": "Lataus", "value": "auto"}];

  useEffect(() => {
    const getTravelReviews = async () => {
      const result = await GetTravelBills(getUserId());
      if (result?.travelReviews) {
        setTravelBills(result.travelReviews);
      }
    };

    // getTravelReviews();
  }, []);

  useEffect(() => {
    // setAcceptVisible(false);
    // let selectedTravelbills = travelBills.find((item) => {
    //   return item.selected;
    // });
    // if (selectedTravelbills) {
    //   setAcceptVisible(true);
    // }
  }, [travelBills]);

  const onAcceptClick = async (travelBillId) => {
    console.log("TODO: Continue this, when the real backend system is available.");
    console.log("onAcceptClick, travelBillId = " + travelBillId);
    console.log("");

    /*
    const travelBill = await GetTravelBill(travelBillId);
    if (travelBill) {
      await AcceptTravelBill(travelBill);
      props.history.push("/travelbills");
    }*/
  };

  const onEditClick = () => {
    // props.history.push("/travelbill", { travelId: "123xyz" });
  };

  const onCheckAll = (event) => {
    selectTravelbill("", event.target.checked);
  };

  const onCheck = (id, checked) => {
    selectTravelbill(id, checked);
  };

  const selectTravelbill = (travelBillId, checked) => {
    setDebugInfo("");

    const newTravelbills = travelBills.slice();

    if (travelBillId) {
      newTravelbills.forEach((item) => {
        if (item.id === travelBillId) {
          item.selected = checked;
          return item;
        }
      });
    } else {
      newTravelbills.forEach((item) => {
        item.selected = checked;
        return item;
      });
    }

    setTravelBills(newTravelbills);
  };

  const onAcceptAll = async () => {
    console.log("onAcceptAll");

    let acceptedTravelbills = travelBills.filter((item) => {
      if (item.selected) {
        return item;
      }
      return false;
    });

    setDebugInfo("Accepting: " + JSON.stringify(acceptedTravelbills));

    const result = await AcceptTravelBills(JSON.stringify(acceptedTravelbills));

    if (result) {
      notify("success", t("travelbills_accepted"));
      setTravelBills([]);
    } else {
      notify("error", t("travelbills_accepted_error"));
    }
  };

  const notify = (type, message) => {
    if (type === "error") {
      toast.error(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      toast.success(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  return (
    <>
      <Container
        fluid
        style={{
          marginBottom: "150px",
          marginLeft: "5px",
          paddingLeft: "0px",
          marginRight: "0px",
          paddingRight: "0px",
        }}>
        <div className='container-header'>
          <h1>
            {t("settings")} <small> {t("settings_intro_small")}</small>
          </h1>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>
                {" "}
                <Form>
                  <Form.Check label='' type='checkbox' onChange={onCheckAll} />
                </Form>
              </th>
              <th>{t("setting")}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {settings.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <Form>
                      <Form.Check
                        label=''
                        type='checkbox'
                        checked={item.selected ? item.selected : false}
                        onChange={() => {
                          onCheck(item.id, !item.selected);
                        }}
                      />
                    </Form>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.value}</td>
                  <td>
                    <Button style={{ margin: "2px" }} variant='info' onClick={onEditClick} size='sm'>
                      <FaGlasses color='white' style={{ margin: "5px" }} />
                      {t("edit")}
                    </Button>

                    {/* <Button
                      variant="success"
                      onClick={() => {
                        onAcceptClick(item.id);
                      }}
                      size="sm"
                    >
                      {t("accept")}
                    </Button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <Button
                  variant='success'
                  onClick={() => {
                    onAcceptAll();
                  }}
                  size='sm'
                  style={{ visibility: acceptVisible ? "visible" : "hidden" }}>
                  <FaCheck style={{ margin: "5px" }} />
                  {t("accept")}
                </Button>
              </td>
              <td colSpan={4}></td>
            </tr>
          </tfoot>
        </Table>
        <code>{debugInfo}</code>
      </Container>
    </>
  );
};

export default Settings;
