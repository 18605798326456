import React from "react";
import { Link } from "react-router-dom";
import { Jumbotron, Container, Button } from "react-bootstrap";
import bgimage from "../../images/header_bg.jpg";
import { useAuth } from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";

const Home = (props) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();

  return (
    <div>
      {!isLoggedIn && (
        <Jumbotron
          style={{
            backgroundImage: `url(${bgimage})`,
            backgroundSize: "cover",
            textShadow: "2px 2px #000",
            marginTop: "1px",
          }}
        >
          <Container style={{ color: "white" }}>
            <h1>{t('charging')}</h1>
            <p>{t('charging_intro')}</p>
          </Container>
          <br />
          <Link to="/main">
            <Button renderas="button" variant="info">
              <span>{t('login')}</span>
            </Button>
          </Link>
        </Jumbotron>
      )}
      <h1>{t('application_header')}</h1>
      <dl>
        <li>TODO: Yleinen esittely</li>
        <li>TODO: Kuva</li>
      </dl>
    </div>
  );
};

export default Home;
