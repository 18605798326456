import React, { useState, useEffect } from "react";
import { Navbar, Nav, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { LOCALES, COOKIE } from "../Constants";
import { GetTravelBillsQty } from "../Services/Api/TravelBills";
import { GetExpenseBillsQty } from "../Services/Api/ExpenseBills";
// import "./App.css";

const TopNavigation = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(LOCALES.FINNISH.code);
  const [travelBillsQty, setTravelBillsQty] = useState(0);
  const [expenseBillsQty, setExpenseBillsQty] = useState(0);

  useEffect(() => {
    const localeValue = Cookies.get(COOKIE.LOCALE);

    if (localeValue) {
      setLanguage(localeValue);
    }
  }, []);

  // TODO: Check this -> This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.

  const getQtys = async () => {
    const tQty = await GetTravelBillsQty();
    const eQty = await GetExpenseBillsQty();

    setTravelBillsQty(tQty);
    setExpenseBillsQty(eQty);
  };

  useEffect(() => {
    // getQtys();
  }, []);

  const onChangeLanguage = (languageCode) => {
    Cookies.set(COOKIE.LOCALE, languageCode);

    if (i18n.language !== languageCode) {
      i18n.changeLanguage(languageCode);
      setLanguage(languageCode);
    }
  };

  return (
    <Navbar bg="light" expand="xl" fixed="top">
      <Navbar.Brand href="/">Muuntuva</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/users" onClick={getQtys}>
            {t("users")}
            <span>
              {travelBillsQty !== 0 && (
                <Badge pill variant="warning" style={{ verticalAlign: "top" }}>
                  {travelBillsQty}
                </Badge>
              )}
            </span>
          </Nav.Link>
          <Nav.Link as={Link} to="/settings" onClick={getQtys}>
            {t("settings")}
            <span>
              {expenseBillsQty !== 0 && (
                <Badge pill variant="warning" style={{ verticalAlign: "top" }}>
                  {expenseBillsQty}
                </Badge>
              )}
            </span>
          </Nav.Link>
        </Nav>
        <Button
          variant={
            language === LOCALES.FINNISH.code ? "primary" : "outline-secondary"
          }
          style={{ marginRight: "5px" }}
          onClick={() => onChangeLanguage(LOCALES.FINNISH.code)}
        >
          {LOCALES.FINNISH.label}
        </Button>
        <Button
          variant={
            language === LOCALES.ENGLISH.code ? "primary" : "outline-secondary"
          }
          style={{ marginRight: "0px" }}
          onClick={() => onChangeLanguage(LOCALES.ENGLISH.code)}
        >
          {LOCALES.ENGLISH.label}
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNavigation;
