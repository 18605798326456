import ApiClient from "./ApiClient";
import { URIS, BACKEND_STATUS } from "./ApiClient";
import resolve from "./Resolve";

export const GetTravelBillsQty = async (userId) => {
  console.log("GETting from " + URIS.TRAVEL_BILLS_QTY);

  const result = await resolve(
    ApiClient.get(URIS.TRAVEL_BILLS_QTY, { params: { userId: userId } })
  );

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data?.travelbillsqty) {
      return result.data.travelbillsqty;
    }
  }
  return 0;
};

export const GetTravelBills = async (userId) => {
  console.log("GETting from " + URIS.TRAVEL_BILLS);

  const result = await resolve(
    ApiClient.get(URIS.TRAVEL_BILLS, { params: { userId: userId } })
  );

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};

export const GetTravelBill = async (travelId) => {
  console.log("GETting from " + URIS.TRAVEL_BILL);

  const result = await resolve(
    ApiClient.get(URIS.TRAVEL_BILL, { params: { travel_id: travelId } })
  );

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};

// export const SendTravelBill = async () => {
//   console.log("Sending to " + URIS.TRAVEL_BILL);

//   const debugUrl = "http://localhost:3333/";
//   const debugPayload = { id: 1, name: "debug travelbill" };

//   const result = await resolve(ApiClient.post(debugUrl, debugPayload));

//   if (result?.status === BACKEND_STATUS.SUCCESS) {
//     if (result?.data) {
//       return result.data;
//     }
//   }

//   return result;
// };

export const AcceptTravelBill = async (travelBill) => {
  const debugUrl = "http://localhost:3333/";
  const debugPayload = { id: 1, name: "debug travelbill" };

  const result = await resolve(ApiClient.post(debugUrl, debugPayload));

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};

export const AcceptTravelBills = async (travelBills) => {
  // Send to common service on dev_server
  const debugUrl = "/api/travel/acceptance";

  const result = await resolve(ApiClient.post(debugUrl, travelBills));

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};


export const RejectTravelBill = async (travelBill) => {
  const debugUrl = "http://localhost:3333/";
  const debugPayload = { id: 1, name: "debug travelbill" };

  const result = await resolve(ApiClient.post(debugUrl, debugPayload));

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      return result.data;
    }
  }

  return result;
};
