export const APP_STATE = {
  PUBLIC: "PUBLIC_LOGIN",
  PRIVATE: "MAIN_APP",
  AUTH: "CHECKING_LOGIN",
  UNKNOWN: "UNKNOWN",
};

export const STORAGE = {
  KEY_USER_DATA: "muuntuva-user-data",
};

export const COOKIE = {
  LOCALE: "muuntuva_locale",
};

export const LOCALES = {
  ENGLISH: { code: "en", label: "En" },
  FINNISH: { code: "fi", label: "Fi" },
};
