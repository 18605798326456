import { STORAGE } from "../Constants";

export const getToken = () => {
  const storedData = JSON.parse(sessionStorage.getItem(STORAGE.KEY_USER_DATA));
  
  if (storedData?.token) {
    return storedData.token;
  } else {
    return '';
  }
};

export const getUserId = () => {
  const storedData = JSON.parse(sessionStorage.getItem(STORAGE.KEY_USER_DATA));
  
  if (storedData?.userId) {
    return storedData.userId;
  } else {
    return '';
  }
};
