import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "js-cookie";
import { COOKIE, LOCALES } from "../Constants";

i18n
  .use(Backend)
  .use(LanguageDetector)
  // .use(i18nextHttpBackend)
  .use(initReactI18next)
  .init({
    lng: Cookies.get(COOKIE.LOCALE) || LOCALES.FINNISH.code,
    fallbackLng: LOCALES.FINNISH.code,
    debug: false,
    ns: "translation",
    defaultNS: "translation",

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
