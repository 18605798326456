import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Alert, Button, Image } from "react-bootstrap";
import { useAuth } from "../../Context/AuthContext";
import "./Login.css";
import { PostLogin } from "../../Services/Api/Authentication";
import companyLogo from "../../images/logo192.png";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const { t } = useTranslation();
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { login, logout } = useAuth();

  let referer = "/";
  if (props?.location?.state?.referer) {
    referer = props.location.state.referer;
  }
  
  useEffect(() => {
    logout();
  }, [logout]);

  //Param Extractor
  const parseQueryString = (params = "") => {
    const rawParams = params.replace("?", "").split("&");
    const extractedParams = {};
    rawParams.forEach((item) => {
      item = item.split("=");
      extractedParams[item[0]] = item[1];
    });
    return extractedParams;
  };

  // TODO: If user and token are given in a query string, save them and continue.
  // console.log("  Login screen  ");
  // const params = parseQueryString(props?.location?.search);
  // console.log(" props => " + JSON.stringify(params));
  // // props => {"email":"teppo.testi@baari.fi","token":"123456abc"}
  // console.log(" .............. ");
  // // const login = useCallback((userName, userId, token, expirationTime) => {
  // if (params?.email && params?.token) {
  //    // login(params.email, params.email, params.token);
  // }
  // -----------------------------------------------------------

  useEffect(() => {
    const params = parseQueryString(props?.location?.search);

    if (params?.user_name && params?.token) {
      login(params.user_name, params.email, params.token);
      props.history.push(referer);
    }
  }, [login, props.history, props?.location?.search, referer]);

  const onLoginClicked = async () => {
    setIsError(false);
    setErrorMessage("");
    setUserName("");
    const result = await PostLogin(user_name, password, login);

    console.log("onLoginClicked: result = " + JSON.stringify(result, null, 2));

    if (!result) {
      setIsError(true);
      setErrorMessage(t("cannot_connect_try_again_later"));
    } else if (result?.status && result?.status === "failed") {
      setIsError(true);
      setErrorMessage(t("invalid_user_or_password"));
    } else {
      setUserName(result.name);
      props.history.push(referer);
    }
  };

  // if (isLoggedIn) {
  //    return <Redirect to={referer} />;
  // }

  const validateForm = () => {
    return user_name.length > 0 && password.length > 0;
  };

  return (
    <div className="Login">
      <Image
        src={companyLogo}
        rounded
        width="100"
        style={{ marginBottom: "20px" }}
      />
      <Form>
        <Form.Group size="lg" controlId="user_name">
          <Form.Label>{t("user_name")}:</Form.Label>
          <Form.Control
            autoFocus
            type="user_name"
            value={user_name}
            onChange={(e) => setUserName(e.target.value)}
            placeholder={'For development use: debug'}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>{t("password")}:</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={'For development this is not validated'}
          />
        </Form.Group>
        <Button onClick={onLoginClicked} disabled={!validateForm()}>
          {t("login")}
        </Button>
        {isError && (
          <Alert style={{ marginTop: "30px" }} variant={"danger"}>
            {errorMessage}
          </Alert>
        )}
      </Form>
    </div>
  );
};

export default Login;
