import React, { useEffect, useState } from "react";
import { Container, Button, Table, Row, Col, Modal, Form } from "react-bootstrap";
import { GetTravelBill, AcceptTravelBill, RejectTravelBill } from "../../Services/Api/TravelBills";
import { GetTravelBills, AcceptTravelBills } from "../../Services/Api/TravelBills";
import TravelStart from "../../Components/TravelBill/TravelStart";
import TravelEnd from "../../Components/TravelBill/TravelEnd";
import TravelDestination from "../../Components/TravelBill/TravelDestination";
import "../../Components/TravelBill/TravelBill.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FaCheck, FaGlasses } from "react-icons/fa";

const Users = (props) => {
  const { t } = useTranslation();
  const [travelBills, setTravelBills] = useState([]);
  const [acceptVisible, setAcceptVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [travelBill, setTravelBill] = useState();
  const [rejectionMessage, setRejectionMessage] = useState("");

  const settings = [{id: 1, "name": "Nimi", "value": "eJeep"}, {id: 2, "name": "Nimi", "value": "N/A"}];

  // TODO: Remove later
  const [debugInfo, setDebugInfo] = useState("");

  useEffect(() => {
    // setTravelBill();
    // const getTravelBill = async () => {
    //   if (!props?.location?.state?.travelId) return;
    //   const result = await GetTravelBill(props.location.state.travelId);
    //   if (result) {
    //     setTravelBill(result);
    //   }
    // };
    // getTravelBill();
  }, [props.location]);

  // const renderDestinations = (travelDestinations) => {
  //   let result = "";
  //   if (!travelDestinations) return result;
  //   let index = 0;

  //   return travelDestinations.map((destination) => (
  //     <TravelDestination key={index++} destination={destination} />
  //   ));
  // };

  const onAcceptClicked = async () => {
    // await AcceptTravelBill(travelBill);
    // props.history.push("/travelbills");
  };

  const onEditClick = () => {
    
    console.log("onEditClick, opening user screen");

    props.history.push("/user", { userId: "123xyz" });
  };

  const onCheckAll = (event) => {
    selectTravelbill("", event.target.checked);
  };

  const onCheck = (id, checked) => {
    selectTravelbill(id, checked);
  };

  const selectTravelbill = (travelBillId, checked) => {
    setDebugInfo("");

    const newTravelbills = travelBills.slice();

    if (travelBillId) {
      newTravelbills.forEach((item) => {
        if (item.id === travelBillId) {
          item.selected = checked;
          return item;
        }
      });
    } else {
      newTravelbills.forEach((item) => {
        item.selected = checked;
        return item;
      });
    }

    setTravelBills(newTravelbills);
  };

  const onAcceptAll = async () => {
    console.log("onAcceptAll");

    let acceptedTravelbills = travelBills.filter((item) => {
      if (item.selected) {
        return item;
      }
      return false;
    });

    setDebugInfo("Accepting: " + JSON.stringify(acceptedTravelbills));

    const result = await AcceptTravelBills(JSON.stringify(acceptedTravelbills));

    if (result) {
      notify("success", t("travelbills_accepted"));
      setTravelBills([]);
    } else {
      notify("error", t("travelbills_accepted_error"));
    }
  };

  const onRejectClicked = async () => {
    // setShowModal(false);
    // travelBill.rejectionMessage = rejectionMessage;
    // await RejectTravelBill(travelBill);
    // props.history.push("/travelbills");
  };

  const renderModal = () => {
    return (
      <Modal animation={false} show={showModal} onHide={() => setShowModal(false)} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("travelbill_rejection")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control placeholder={t("reason_for_rejection")} as='textarea' rows={3} value={rejectionMessage} onChange={(e) => setRejectionMessage(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={onRejectClicked} disabled={rejectionMessage === ""}>
            {t("send")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const notify = (type, message) => {
    if (type === "error") {
      toast.error(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      toast.success(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  return (
    <>
      <Container
        fluid
        style={{
          marginBottom: "150px",
          marginLeft: "5px",
          paddingLeft: "0px",
          marginRight: "0px",
          paddingRight: "0px",
        }}>
        <div className='container-header'>
          <h1>
            {t("users")} <small> {t("users_intro_small")}</small>
          </h1>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>
                {" "}
                <Form>
                  <Form.Check label='' type='checkbox' onChange={onCheckAll} />
                </Form>
              </th>
              <th>{t("setting")}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {settings.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <Form>
                      <Form.Check
                        label=''
                        type='checkbox'
                        checked={item.selected ? item.selected : false}
                        onChange={() => {
                          onCheck(item.id, !item.selected);
                        }}
                      />
                    </Form>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.value}</td>
                  <td>
                    <Button style={{ margin: "2px" }} variant='info' onClick={onEditClick} size='sm'>
                      <FaGlasses color='white' style={{ margin: "5px" }} />
                      {t("edit")}
                    </Button>

                    {/* <Button
                      variant="success"
                      onClick={() => {
                        onAcceptClick(item.id);
                      }}
                      size="sm"
                    >
                      {t("accept")}
                    </Button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <Button
                  variant='success'
                  onClick={() => {
                    onAcceptAll();
                  }}
                  size='sm'
                  style={{ visibility: acceptVisible ? "visible" : "hidden" }}>
                  <FaCheck style={{ margin: "5px" }} />
                  {t("accept")}
                </Button>
              </td>
              <td colSpan={4}></td>
            </tr>
          </tfoot>
        </Table>
        <code>{debugInfo}</code>
      </Container>

      {/* NOTE: Modal with input field could be moved to an own component, if it is possible       */}
      {renderModal()}
    </>
  );
};

export default Users;
