import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Modal, Form } from "react-bootstrap";
import UserDetails from "../../Components/UserDetails/UserDetails";
import {
  GetExpenseBill,
  AcceptExpenseBill,
  RejectExpenseBill,
} from "../../Services/Api/ExpenseBills";
import { useTranslation } from "react-i18next";

const User = (props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState();
  const [rejectionMessage, setRejectionMessage] = useState("");

  useEffect(() => {
    console.log("user: useEffect, userId = " + props.location.state.userId);

    console.log("props.location.state: " + JSON.stringify(props.location.state));

    // setUser();

    // const getExpenseBill = async () => {
    //   if (!props?.location?.state?.expenseId) return;
    //   const result = await GetExpenseBill(props.location.state.expenseId);
    //   if (result) {
    //     setExpenseBill(result);
    //   }
    // };

    // DEBUG:
    const userResult = {name: "eJeep User", userName: "ejeep", passWord: "8769768769876", deviceName: "ard1", deviceNameLastStatus: "2023-05-28 20:36", apikey: "lkjh23kj5h45" };
    setUser (userResult);

    // DEBUG

    // getExpenseBill();
  }, [props.location]);

  // if (!expenseBill) return "";

  const onAcceptClicked = async () => {
    await AcceptExpenseBill(user);
    props.history.push("/users");
  };

  const onRejectClicked = async () => {
    setShowModal(false);
    user.rejectionMessage = rejectionMessage;
    await RejectExpenseBill(user);

    props.history.push("/users");
  };

  const renderModal = () => {
    return (
      <Modal
        animation={false}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("expense_rejection")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              placeholder={t("reason_for_rejection")}
              as="textarea"
              rows={3}
              value={rejectionMessage}
              onChange={(e) => setRejectionMessage(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={onRejectClicked}
            disabled={rejectionMessage === ""}
          >
            {t("send")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <Container style={{ marginBottom: "150px" }}>
        <div className="container-header">
          <h1>
            {t("user")} <small>{/* expenseBill?.expenseName */}</small>
          </h1>
          <pre>{props.location.state.userId}</pre>
          {/* <code>{expenseBill.travellerEmail}</code> */}
        </div>
        <Container>
          <Row>
            <Col>
              <UserDetails user={user} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="success" onClick={onAcceptClicked}>
                {t("save")}
              </Button>
            </Col>
            <Col>
              <Button variant="danger" onClick={() => setShowModal(true)}>
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* NOTE: Modal with input field could be moved to an own component, if it is possible       */}
      {renderModal()}
    </>
  );
};

export default User;
