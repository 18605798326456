import { createContext, useContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  token: null,
  userName: "",
  saveUserName: () => {},
  login: () => {},
  logout: () => {},
  sessionLoggedIn: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}
