import React from "react";
import { Route } from "react-router-dom";
import Login from "../Screens/Login";
import Home from "../Screens/Home";
import Main from "../Screens/Main";
import PrivateRoute from "./PrivateRoute";

import Settings from "../Screens/Settings";
import Users from "../Screens/Users";
import User from "../Screens/User";

const AppRouter = () => {
  return (
    <>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/main" component={Main} />
      <PrivateRoute path="/users" component={Users} />
      <PrivateRoute path="/user" component={User} /> 
      <PrivateRoute path="/settings" component={Settings} />
      {/* <PrivateRoute path="/expenses" component={Expenses} />*/}
    </>
  );
};

export default AppRouter;
