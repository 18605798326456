import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import "./Main.css";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1>{t("application_header")}</h1>
            <p>{t("travelbills_info")}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Main;
