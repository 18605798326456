import React from "react";
import "./TravelBill.css";

const TravelBillField = ({ caption, value }) => {
    return (
      <div style={{ padding: "5px" }}>
        <span style={{ fontWeight: "bold" }}>{caption}:</span>{" "}
        <span style={{ padding: "5px", color: "#116466" }}>{value}</span>
      </div>
    );
  };

export default TravelBillField;
