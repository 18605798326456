import { useAuth } from "../Context/AuthContext";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Authentication = () => {
  const { t } = useTranslation();
  const { isLoggedIn, logout } = useAuth();

  const logoutHandler = () => {
    logout();
  };

  return (
    <>
      {isLoggedIn && (
        <Button variant="secondary" onClick={logoutHandler}>
          {t('logout')}
        </Button>
      )}
      {/* {!isLoggedIn && (
        <Link to="/main">
            <Button renderas="button">
              <span>Kirjaudu sisään</span>
            </Button>
          </Link>
      )} */}
    </>
  );
};

export default Authentication;
