import { useAuth } from "../Context/AuthContext";
import { Navbar, Container } from "react-bootstrap";
import Authentication from "./Authentication";

const Footer = () => {
  const { userName, isLoggedIn } = useAuth();

  return (
    <div className="fixed-bottom">
      <Navbar bg="dark" style={{height: "50px"}}>
        <Container>
        <span><code>v {process.env.REACT_APP_BUILD_versionName}</code></span>
        {isLoggedIn && <code>{userName}</code>}s
        </Container>
        <Authentication />
      </Navbar>
    </div>
  );
};

export default Footer;
